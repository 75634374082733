const FORMS = {
  ServiceForm: {
    serviceId: "service_b46klu2",
    templateId: "template_hdjwig8",
    publicKey: "DOp11aRpz25cHWNzc",
  },
  AssessmentForm: {
    serviceId: "service_b46klu2",
    templateId: "template_rarwdie",
    publicKey: "DOp11aRpz25cHWNzc",
  },
};

export default FORMS;
