const pageContactText = {
  TITLE: "Contact Title",
  TEXT: (
    <div>
      Here is some text. Email me:{" "}
      <a href="mailto: abc@example.com">Send Email</a>
    </div>
  ),
};

export default pageContactText;
