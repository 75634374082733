const goldGreyBlue = {
  primaryColor: "#F4BC16",
  primaryFontColor: "#666",
  secondaryColor: "#666",
  secondaryVariant: "#999",
  callToActionPrimary: "#256cb8",
  callToActionSecondary: "#333",
  secondaryLight: "#e2e6e7",
  secondaryDark: "#333",
  tertiaryColor: "#0f88f2",
  tertiaryColorVariant: "#0f88f2",
  tertiaryColorLight: "#3f9ff2",
  tertiaryColorDark: "#004f94",
  mainBackground: "#fff",
  headerBackground: "#F5F5F5",
  headerFontColor: "#999",
  headerLinkColor: "#999",
  headerBorderColor: "#E2E6E7",
  rotationTitleColor: "#fff",
  rotationTextColor: "rgba(255, 255, 255, 0.8)",
  rotationBackground: "rgba(0, 0, 0, .8)",
  linkColor: "#333",
  linkHover: "#F4BC16",
  thinBannerBackgroundColor: "#F4BC16",
  thinBannerFontColor: "#000",
  thinBannerLink: "#0b5396",
};

export default goldGreyBlue;
