const COMPANY = {
  NAME: "Streamline",
  TAGLINE: "Intercoms and Access Control",
  OWNER: "Kevin Linstrum",
  EMAIL: "installs@kevinlinstrum.tech",
  PHONE: "(347)986-4298",
  LINKEDIN: "link",
};

export default COMPANY;
